<script setup>
import { useEventTracking } from '#imports';

defineProps({
  posts: {
    type: Array,
    required: true,
  },
  sectionSlug: {
    type: String,
    default: '',
  },
  titleImage: {
    type: [Object, String],
    default: '',
  },
  titleImageMobile: {
    type: [Object, String],
    default: '',
  },
  quoteBlock: {
    type: [Object, String],
    default: '',
  },
});

function getImageAssetId(image) {
  return image.asset._ref;
}

function getImageCaption(image) {
  return `Изображение-${getImageAssetId(image)}`;
}

function getStyles({ size, _type: type }) {
  const isNews = type === 'news';
  if (size === 'small')
    return {
      gridColumnEnd: 'span 1',
      gridRowEnd: isNews ? 'span 1' : 'span 2',
    };
  if (size === 'medium')
    return {
      gridColumnEnd: 'span 2',
      gridRowEnd: 'span 2',
    };
  if (size === 'big')
    return {
      gridColumnEnd: isNews ? 'span 1' : 'span 2',
      gridRowEnd: isNews ? 'span 2' : 'span 4',
    };
  return '';
}

function getPostRoute({ _type: type, slug }) {
  const isNews = type === 'news';

  return {
    name: isNews ? 'news-slug' : 'articles-slug',
    params: { slug: slug.current },
  };
}

function getSectionRoute(section) {
  return {
    name: 'themes-slug',
    params: { slug: section },
  };
}

const handleClick = (eventName) => {
  useEventTracking('click', eventName);
};
</script>

<template>
  <section class="section">
    <NuxtLink v-if="sectionSlug" :to="getSectionRoute(sectionSlug)">
      <div
        v-if="titleImage || titleImageMobile"
        class="section__title"
        @click="handleClick(`Click on ${sectionSlug} section`)"
      >
        <SanityImage
          v-if="titleImage"
          :alt="getImageCaption(titleImage)"
          :asset-id="getImageAssetId(titleImage)"
          auto="format"
          class="section__title-image"
        />
        <SanityImage
          v-if="titleImageMobile"
          :alt="getImageCaption(titleImageMobile)"
          :asset-id="getImageAssetId(titleImageMobile)"
          auto="format"
          class="section__title-image section__title-image--mobile"
        />
      </div>
    </NuxtLink>
    <div v-if="!sectionSlug">
      <div v-if="titleImage || titleImageMobile" class="section__title">
        <SanityImage
          v-if="titleImage"
          :alt="getImageCaption(titleImage)"
          :asset-id="getImageAssetId(titleImage)"
          auto="format"
          class="section__title-image"
        />
        <SanityImage
          v-if="titleImageMobile"
          :alt="getImageCaption(titleImageMobile)"
          :asset-id="getImageAssetId(titleImageMobile)"
          auto="format"
          class="section__title-image section__title-image--mobile"
        />
      </div>
    </div>

    <SectionQuoteBlock v-if="quoteBlock" :quote="quoteBlock" />
    <div class="section__grid">
      <div
        v-for="post in posts"
        :key="post._id"
        :style="getStyles(post)"
        :class="post.isChronicle ? 'section__grid-item--chronicle' : ''"
        class="section__grid-item"
      >
        <a
          v-if="post.isArticleExternalSourceType"
          :href="post.externalSource"
          target="_blank"
        >
          <PostCard :post="post" />
        </a>
        <NuxtLink v-else :to="getPostRoute(post)">
          <PostCard :post="post" />
        </NuxtLink>
      </div>
    </div>
    <div
      class="section__button"
      @click="handleClick(`Show all materials of ${sectionSlug}`)"
    >
      <NuxtLink v-if="sectionSlug" :to="getSectionRoute(sectionSlug)">
        <ButtonComponent button-caption="Все материалы раздела" />
      </NuxtLink>
    </div>
  </section>
</template>

<style lang="postcss">
.section {
  max-width: 1320px;
  margin: 0 auto;

  &__button {
    margin-top: 32px;
    display: flex;
    justify-content: center;

    @media (--small-vp) {
      margin-top: 16px;
    }
  }

  &__title {
    margin-bottom: 5px;
    position: relative;
  }

  &__title::after {
    content: '';
    height: 4px;
    border-radius: 16px;
    background: rgba(255 255 255 / 90%);
    width: 0;
    display: block;
    position: absolute;
    bottom: 10px;
    transition: width 0.2s ease;
  }

  &__title:hover::after {
    cursor: pointer;
    content: '';
    width: 100%;
  }

  &__title-image {
    width: 100%;
    height: auto;
    margin-bottom: 16px;

    &--mobile {
      display: none;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-flow: dense;
    gap: 24px;
  }

  &__grid-item--chronicle {
    box-shadow: 0 0 0 2px var(--accent-color);
    border-radius: 16px;
  }

  @media (--tablet-vp) {
    &__grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (--large-vp) {
    &__grid {
      gap: 20px;
    }
  }

  @media (--small-vp) {
    &__title:hover::after {
      content: none;
    }

    &__title-image {
      display: none;

      &--mobile {
        display: block;
        margin-bottom: 8px;
      }
    }

    &__grid {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}
</style>
